<template>
    <tr 
    class="tw-border-b tw-border-solid tw-border-gray-100 last:tw-border-b-0 tw-group even:tw-bg-gray-50/50">
        <td class="tw-px-2 tw-py-2">
            <div
                class="tw-w-full tw-py-2 tw-px-1 tw-text-sm tw-font-medium">
                {{ item.name }}
            </div>
        </td>

        <td class="tw-px-2 tw-py-2">
            <div
                class="tw-w-full tw-py-2 tw-px-1 tw-text-sm tw-text-end tw-font-bold tw-text-emerald-700 tw-flex tw-items-center tw-gap-1">
                <span>{{ formatNumber (item.shipping_cost, {}) }}</span>
                <span>{{ $t('currencies.LYD') }}</span>
            </div>
        </td>

        <td class="tw-px-2 tw-py-2">
            <CityActions :city="item" />
        </td>

    </tr>
</template>

<script setup>
import { defineProps, toRef } from 'vue';
import CityActions from './CityActions.vue';

const props = defineProps(['item', 'index', 'page', 'excludeAds'])
const item = toRef(props, 'item')
item

const formatNumber = (number, type = {style: 'currency', currency: 'LYD'}) => {
    return new Intl.NumberFormat('en', { maximumFractionDigits: 2, ...type }).format(number);
}
</script>

<style></style>