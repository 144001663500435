<template>
    <div class="tw-relative ltw-z-[15] tw-w-full" ref="container">
        <p class="tw-text-xs tw-mb-1 tw-font-semibold">{{ $t('fields.product_name') }}</p>
        <button @click="visible = !visible"
            :class="[visible && '!tw-border-b']"
            class="tw-p-1.5 ltr:tw-pr-3 rtl:tw-pl-3 tw-cursor-pointer tw-w-full hover:tw-bg-gray-50 tw-duration-200 tw-border-b-2x tw-bg-white tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-shadow-smx tw-h-[32px] tw-flex tw-items-center">

            <div class="tw-flex tw-items-center tw-gap-2">
                <icon icon="solar:box-minimalistic-linear" class="tw-text-lgx tw-text-gray-400" />
                <p v-if="!selected.length" class="tw-text-xs tw-text-gray-700">{{ $t('fields.product_name') }}</p>
                <div v-if="selected.length" class="tw-flex tw-gap- tw-divide-x rtl:tw-divide-x-reverse tw-overflow-hidden">
                    <p v-for="s in selected.slice(0,3)" :key="s" class="tw-text-[10px] tw-px-1 tw-text-gray-700 tw-whitespace-nowrap">{{ products.find(p => p.id == s)?.name }}</p>
                </div>
                <div v-if="selected.length > 3" class="tw-flex tw-gap- tw-divide-x tw-overflow-hidden">
                    <p class="tw-text-[10px] tw-px-1 tw-text-gray-700 tw-whitespace-nowrap">...</p>
                </div>
            </div>
        </button>


        <transition class="tw-duration-200" enter-from-class="tw-translate-y-[10px]  tw-opacity-0"
            leave-to-class="tw-translate-y-[10px]  tw-opacity-0">
            <div v-if="visible" class="tw-duration-100">
                <div
                    class="tw-absolute tw-z-10 tw-top-[calc(100%+5px)] ltr:tw-left-0 rtl:tw-right-0 tw-flex tw-flex-col tw-w-[400px] tw-max-w-[300px] tw-h-[100px]x tw-bg-white tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-shadow-md">
                    <div class="tw-p-2">
                        <FormVueSelect append-to-body hide-errors multiple hide-label :searchable="true" v-model="selectedValues" max-height="200" class="!tw-h-fit"
                            :options="products" option-value="id" :label="$t('fields.product')" :error="errors.product_id &&
                                $t('errors.' + errors.product_id, {
                                    field: $t('fields.product'),
                                })
                                ">
                            <template v-slot:option="{ option }">
                                <p class="tw-p-2 tw-rounded tw-text-xs tw-px-4">{{
                                    option.name }}</p>
                            </template>
                            <template v-slot:selected-option-container="{ params }">
                                <div class="vs__selected tw-text-xs tw-py-0 tw-px-2" :class="params.option.color" @click="removeValue(params.option.id)">
                                    <p class="tw-max-w-[90px] tw-truncate">{{ params.option.label }}</p>
                                    </div>
                            </template>
                        </FormVueSelect>
                    </div>
                    <div
                        class="tw-p-2 tw-border-t tw-border-solid tw-border-gray-200 tw-flex tw-items-center tw-justify-end tw-gap-2">
                        <a-button @click="visible = false" class="!tw-w-[80px] !tw-h-[30px] !tw-min-h-0 tw-text-xs">{{ $t('buttons.done')
                            }}</a-button>
                    </div>
                </div>
            </div>
        </transition>

    </div>
</template>

<script setup>
import { useStateStore } from '@/stores/app/state';
import { onClickOutside } from '@vueuse/core';
import FormVueSelect from "@/components/form/FormVueSelect";
import { computed, ref, inject, watch } from 'vue';


const container = ref(null);
onClickOutside(container, () => false)//visible.value = false);

const filter = inject('filter')
const filters = inject('filters')

const options = ref([])

const visible = ref(false);
const stateStore = useStateStore();
const products = computed(() => [{ id: null }, ...stateStore.products.map(p => ({ ...p, label: p.name }))]);
const selected = computed({
    get: () => filters.value.product_id,
    set: v => filters.value.product_id = v
});

const errors = ref({})
const selectedValues = ref(filters.value.product_id);

watch(() => selectedValues.value, () => {
    selected.value = selectedValues.value
    
})

const removeValue = value => {
    selectedValues.value = selectedValues.value.filter(v => v != value)
}

const getOptions = async () => {
    // loading.value = true;
    // await productsApi.all()
    // .then(
    //     res => {
    //         if(res.data.code == 'SUCCESS') {
                options.value = products.value
                // options.value.map(c => products.value[c.id] = c.name)
    //         }
    //     }
    // )
    // loading.value = false;
}

getOptions()

const onFilter = () => {
    filter();
    visible.value = false;
}

onFilter

</script>

<style></style>