<template>
    <div class="tw-min-h-[700px]">
        <filter-wrapper @filter="onFilter" />

        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-12 tw-mt-2 tw-gap-2">
            <div class="tw-col-span-1 md:tw-col-span-12 lg:tw-col-span-12">
                <KpisWidget />
            </div>

            <div class="tw-col-span-1 md:tw-col-span-12 lg:tw-col-span-12">
                <ProductsRankWidget />
            </div>
        </div>
    </div>

</template>

<script setup>

import KpisWidget from '@/components/dashboard/admin/analytics/KpisWidget.vue';
import ProductsRankWidget from '@/components/dashboard/admin/analytics/ProductsRankWidget.vue';
import FilterWrapper from '@/components/dashboard/admin/FilterWrapper.vue';
import { ref, provide } from 'vue';


const filters = ref({
    created_at: { from: null, to: null },
    treated_at: { from: null, to: null },
    dropped_at: { from: null, to: null },
    agent_status: [],
    agent_id: [],
    product_id: [],
    delivery_status: []
});

const callbacks = ref([]);

const onFilter = () => {
    callbacks.value.forEach(callback => callback());
}

provide('filters', filters);
provide('register', callback => callbacks.value.push(callback));

</script>

<style></style>