import SettingsView from "@/views/admin/settings/SettingsView";

export default [
  {
    name: "pages.admin.settings",
    path: "settings",
    component: SettingsView
  },
  
];
