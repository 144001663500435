<template>
    <div class="tw-bg-white tw-p-2 tw-border tw-border-solid tw-border-gray-200">
        <div class="tw-flex tw-items-center tw-justify-between">



        </div>
        <div class="tw-roundedx tw-border tw-border-solid tw-border-gray-100 tw-overflow-auto">
            <table class="tw-min-w-full tw-leading-normal tw-w-full">
                <thead class="">
                    <tr>
                        <th v-for="c in columns" :key="c.name" :class="c.classes"
                            class="tw-px-5 tw-text-[10px] last:tw-border-e-0 tw-whitespace-nowrap tw-border-e tw-py-3 tw-border-b tw-borderx tw-border-gray-100 tw-bg-gray-50 tw-text-start tw-font-semibold tw-text-gray-600 tw-uppercase tw-tracking-wider">
                            <p>{{ $t(c.label) }}</p>
                        </th>
                    </tr>
                </thead>

                <tbody v-if="loading">
                    <tr class="tw-border-b tw-border-solid tw-border-gray-100 last:tw-border-b-0 tw-group even:tw-bg-gray-50/50"
                        v-for="i in 10" :key="i">
                        <td v-for="c in columns" :key="c.name" class="tw-px-2 tw-py-3">
                            <div
                                class="tw-w-full tw-py-3 tw-bg-gray-50 group-even:tw-bg-white  tw-rounded tw-min-w-[30px] tw-animate-pulse">

                            </div>
                        </td>
                    </tr>
                </tbody>

                <tbody v-if="!loading && data.length">
                    <CityRow v-for="(item) in data" :key="item" :item="item" />
                </tbody>

                <tbody v-if="!loading && !data.length">
                    <tr
                        class="tw-border-b tw-border-solid tw-border-gray-100 last:tw-border-b-0 tw-group even:tw-bg-gray-50/50">
                        <td :colspan="columns.length">
                            <p class="tw-p-2 tw-py-5 tw-text-center">
                                No data found
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="tw-flex tw-items-center tw-justify-between">
            <div class="tw-flex tw-items-center tw-gap-2">

                <div class="tw-flex tw-items-center tw-gap-2">
                    <select v-model.number="options.per_page" @change="() => getData()"
                        class="tw-w-[100px] tw-outline-none tw-bg-white tw-text-black tw-border tw-border-solid tw-border-gray-200 tw-rounded tw-px-2 tw-py-1">
                        <option :value="5">5</option>
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="50">50</option>
                        <option :value="100">100</option>
                        <option :value="250">250</option>
                        <option :value="500">500</option>
                    </select>
                </div>
            </div>
            <div class="tw-flex tw-items-center tw-gap-2 tw-justify-end tw-mt-4">
                <button @click="onPrev" :disabled="!options.prev_page_url"
                    :class="[!options.prev_page_url && 'tw-bg-gray-50 tw-cursor-not-allowed tw-text-gray-300 tw-border-gray-100 hover:!tw-bg-gray-50']"
                    class="tw-w-[120px] tw-py-2 tw-border tw-border-solid tw-border-gray-200 hover:tw-bg-gray-100 tw-duration-200">Previous</button>

                <button @click="onNext" :disabled="!options.next_page_url"
                    :class="[!options.next_page_url && 'tw-bg-gray-50 tw-cursor-not-allowed tw-text-gray-300 tw-border-gray-100 hover:!tw-bg-gray-50']"
                    class="tw-w-[120px] tw-py-2 tw-border tw-border-solid tw-border-gray-200 hover:tw-bg-gray-100 tw-duration-200">Next</button>
            </div>
        </div>

    </div>

</template>


<script setup>
import { ref, provide } from 'vue';
import CityRow from './CityRow.vue';
import { paginate } from '@/api/cities';


const options = ref({ per_page: 10 });
const loading = ref(true);
const data = ref([]);


let columns = [
    { label: 'fields.name', sortable: false, classes: [''] },
    { label: 'fields.shipping_cost', sortable: false, classes: ['tw-w-[100px]'] },
    { label: 'fields.actions', sortable: false, classes: ['tw-w-[100px] '] },
]


const getData = async (per_page = options.value.per_page, page = 1) => {
    loading.value = true;
    await paginate({per_page, page})
    .then(
        res => {
            options.value = res.data.cities;
            data.value = res.data.cities.data;
            console.log(res.data.cities.data);
            
        }
    )
    loading.value = false;
}

getData()

const onNext = () => {
    getData(options.value.per_page, options.value.current_page + 1);
}

const onPrev = () => {
    getData(options.value.per_page, options.value.current_page - 1);
}


const onUpdated =  (city) => {
    data.value = data.value.map(d => {
        if (d.id == city.id) {
            return city;
        }
        return d;
    })
}

provide('onUpdated', onUpdated)

</script>

<style></style>