<template>
    <div class="tw-px-4">
        <div class="tw-p-5 tw-bg-white">
            <h1>General</h1>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>