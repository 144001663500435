<template>
    <div>
        <FormVueSelect append-to-body hide-errors multiple :searchable="false" v-model="selectedValues" max-height="200" class="!tw-h-fit"
            :options="confirmations" option-value="value" :label="$t('fields.agent_status')" :error="errors.agent_status &&
                $t('errors.' + errors.agent_status, {
                    field: $t('fields.agent_status'),
                })
                ">
            <template v-slot:option="{ option }">
                <p :class="[option.color]" class="tw-p-2 tw-rounded tw-text-xs tw-px-4">{{
                    $t(option.name) }}</p>
            </template>
            <template v-slot:selected-option-container="{ params }">
                <div class="vs__selected tw-text-xs tw-py-0 tw-px-2" :class="params.option.color" @click="removeValue(params.option.value)">{{ params.option.label
                    }}</div>
            </template>
        </FormVueSelect>
    </div>
</template>

<script setup>
import FormVueSelect from "@/components/form/FormVueSelect";
import { order_confirmations } from '@/config/status';
import { computed, ref, watch, inject } from 'vue';
import { useI18n } from "vue-i18n";

const i18n = useI18n();
const updateFilters = inject('update:filters')
const filters = inject('filters')
const confirmations = computed(() => order_confirmations.map(c => ({ ...c, label: i18n.t(c.name) })))

const errors = ref({})
const selectedValues = ref(filters.value.agent_status);
watch(() => selectedValues.value, () => {
    const newFilters = {
        ...filters.value,
        agent_status: selectedValues.value,
    }

    updateFilters(newFilters)
})

const removeValue = value => {
    selectedValues.value = selectedValues.value.filter(v => v != value)
}

</script>

<style></style>