import {
    server
} from "@/api";


export const confirmation = async (params = {}) => {
    return await server().get('api/analytics/confirmation', { params })
}

export const delivery = async (params = {}) => {
    return await server().get('api/analytics/delivery', { params })
}

export const kpis = async (params = {}) => {
    return await server().get('api/analytics/kpis', { params })
}

export const productPerformance = async (params = {}) => {
    return await server().get('api/analytics/product-performance', { params })
}

export default {
    confirmation,
    delivery,
    kpis,
    productPerformance
}