<template>
    <div>

        <a-action-button @click="visible = true" icon="solar:pen-bold-duotone" intent="warning"></a-action-button>

        <a-popup :visible="visible" @cancel="onCancel">
            <div
                class="tw-mx-auto tw-w-[95%] md:tw-max-w-[800px] tw-overflow-hidden tw-h-fit dark:tw-bg-gray-900 tw-bg-white tw-border tw-border-solid dark:tw-border-gray-400 tw-border-gray-300 tw-shadow-md tw-my-5 tw-rounded">

                <div class="tw-p-5 tw-bg-white">
                    <div class="tw-grid tw-grid-cols-12 tw-gap-x-4 tw-gap-y-0">
                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormField disabled v-model="city.name" :label="$t('fields.name')" type="text" :error="errors.name &&
                                $t('errors.' + errors.name, {
                                    field: $t('fields.name'),
                                })
                                " />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormField left-icon="solar:delivery-bold-duotone" v-model="city.shipping_cost" :label="$t('fields.shipping_cost') + ' ( ' + $t('currencies.LYD') + ' )'" type="number" :error="errors.shipping_cost &&
                                $t('errors.' + errors.shipping_cost, {
                                    field: $t('fields.shipping_cost'),
                                })
                                " />
                        </div>

                    </div>
                </div>

                <div
                    class="tw-p-4 tw-text-lg tw-border-t tw-justify-end tw-border-solid tw-font-medium tw-flex dark:tw-text-gray-700 tw-text-gray-500 tw-items-center tw-gap-4 dark:tw-bg-gray-100 tw-bg-gray-50">
                    <a-button :disabled="loading" @click="onCancel" class="tw-min-w-[120px]"
                        intent="simple">{{
                            $t("buttons.cancel") }}</a-button>
                    <a-button :disabled="loading" :loading="loading" @click="onUpdate"
                        class="tw-min-w-[120px]">{{
                            $t("buttons.update") }}</a-button>
                </div>
            </div>
        </a-popup>
    </div>
</template>

<script setup>
import { ref, defineProps, inject } from "vue";
import FormField from "@/components/form/FormField";
import { update } from "@/api/cities";
import useAlert from "@/composables/useAlert";
import { useI18n } from "vue-i18n";

const props = defineProps(['city'])
const i18n = useI18n()

const visible = ref(false);
const loading = ref(false);
const city = ref(structuredClone(props.city));
const errors = ref({});
const onUpdated = inject('onUpdated')

const onCancel = () => {
    errors.value = {}
    visible.value = false;
};

const onUpdate = async () => {
    loading.value = true;
    errors.value = {}
    return await update(city.value.id, city.value)
        .then(
            r => {
                if (r.data.code == 'SUCCESS') {
                    useAlert(i18n.t('messages.updated-successfully', { field: i18n.t('fields.city') }))
                    onUpdated(r.data.city);
                    onCancel();
                }
            },
            error => {
                if (error.response.status == 422) {
                    for (let e in error.response.data.errors) {
                        errors.value[e] = error.response.data.errors[e][0];
                    }
                }
            }
        )
        .finally(
            () => loading.value = false
        )
};
</script>

<style></style>
