import {
    server
} from "@/api";


export const create = async (city) => {
    return await server().post('api/cities', city)
}

export const all = async (params) => {
    return await server().get('api/cities/all', { params });
}

export const get = async (id, params = {}) => {
    return await server().get('api/cities/' + id, { params });
}

export const paginate = async (params) => {
    return await server().get('api/cities', { params });
}

export const update = async (id, city) => {
    return await server().post(`api/cities/${id}`, city);
}

export default {
    create,
    get,
    all,
    update,
    paginate
}