<template>
    <div class="tw-relative tw-z-20 tw-w-full" ref="container">
        <p class="tw-text-xs tw-mb-1 tw-font-semibold">{{ $t('common.created_at') }}</p>
        <button @click="visible = !visible"
            :class="[visible && '!tw-border-b']"
            class="tw-p-1.5 ltr:tw-pr-3 rtl:tw-pl-3 tw-cursor-pointer tw-w-full hover:tw-bg-gray-50 tw-duration-200 tw-border-b-2x tw-bg-white tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-shadow-smx tw-h-[32px] tw-flex tw-items-center">

            <div class="tw-flex tw-items-center tw-gap-2 tw-w-full">
                <icon icon="solar:calendar-linear" class="tw-text-lgx tw-text-gray-400" />
                <p v-if="isCustom" class="tw-text-xs tw-text-gray-700 tw-flex tw-items-center tw-gap-1">
                    <span>{{ $t(selected.label) }}</span>
                </p>
                <p v-if="!isCustom" class="tw-text-xs tw-text-gray-700 tw-flex tw-items-center tw-gap-3">
                    <span>{{ moment(dates.from).locale($i18n.locale).format('DD MMMM YYYY') }}</span>
                    <span>-</span>
                    <span>{{ moment(dates.to).locale($i18n.locale).format('DD MMMM YYYY') }}</span>
                </p>
            </div>
        </button>


        <transition class="tw-duration-200" enter-from-class="tw-translate-y-[10px]  tw-opacity-0"
            leave-to-class="tw-translate-y-[10px]  tw-opacity-0">
            <div v-if="visible" class="tw-duration-100">
                <div
                    class="tw-absolute tw-top-[calc(100%+5px)] ltr:tw-left-0 rtl:tw-right-0 tw-flex tw-flex-col tw-w-[400px] tw-h-[100px]x tw-bg-white tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-shadow-md">
                    <div class="tw-flex-1">
                        <div class="tw-flex tw-gap-2 tw-flex-wrap tw-p-2">
                            <div v-for="option in options" :key="option.label" role="button" @click="onCustomDateClick(option)"
                                :class="[selected.key == option.key && '!tw-bg-primary-500 !tw-text-white !tw-border-primary-300']"
                                class="tw-h-[25px] tw-text-xs tw-px-2 hover:tw-bg-primary-50 tw-duration-200 hover:tw-border-primary-200 tw-rounded-sm tw-border tw-border-solid tw-border-gray-200 tw-w-fit tw-text-gray-500 tw-font-medium tw-flex tw-items-center">
                                {{ $t(option.label) }}</div>
                        </div>

                        <div class="tw-mt-2x tw-p-2 tw-bg-red-300x tw-flex tw-items-center tw-gap-1">

                            <a-datepicker v-model="dates.from" :placeholder="$t('common.choose-date')"
                                :position="$i18n.locale == 'ar' ? 'right' : 'left'"
                                input-class="!tw-h-[36px] !tw-text-xs" :lang="$i18n.locale"></a-datepicker>

                            <icon :icon="$i18n.locale == 'ar' ? 'solar:arrow-left-line-duotone' : 'solar:arrow-right-line-duotone'" class="tw-text-lg tw-text-gray-400" />

                            <a-datepicker v-model="dates.to" :placeholder="$t('common.choose-date')"
                                :position="$i18n.locale == 'ar' ? 'right' : 'left'"
                                input-class="!tw-h-[36px] !tw-text-xs" :lang="$i18n.locale"></a-datepicker>
                        </div>
                    </div>
                    <div v-if="false"
                        class="tw-p-2 tw-border-t tw-border-solid tw-border-gray-200 tw-flex tw-items-center tw-justify-end tw-gap-2">
                        <a-button  @click="visible = false" intent="text" class="!tw-w-[80px] !tw-h-[30px] !tw-min-h-0 tw-text-xs">{{
                            $t('buttons.cancel') }}</a-button>
                        <a-button @click="onFilter" class="!tw-w-[80px] !tw-h-[30px] !tw-min-h-0 tw-text-xs">{{ $t('buttons.filter')
                            }}</a-button>
                    </div>
                    <div
                        class="tw-p-2 tw-border-t tw-border-solid tw-border-gray-200 tw-flex tw-items-center tw-justify-end tw-gap-2">
                        <a-button @click="visible = false" class="!tw-w-[80px] !tw-h-[30px] !tw-min-h-0 tw-text-xs">{{ $t('buttons.done')
                            }}</a-button>
                    </div>
                </div>
            </div>
        </transition>

    </div>
</template>

<script setup>
import moment from 'moment';
import { ref, computed, inject, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';


const container = ref(null);
const isCustom = ref(true);
onClickOutside(container, () => visible.value = false);
const filter = inject('filter')
const filters = inject('filters')
const today = moment();

const dates = ref({
    from: null,
    to: null
})

watch(() => dates.value, (v) => {

    if(v.from || v.to) {
        let to_date = moment(v.to);
        let from_date = moment(v.from);

        // Check if the hour is 23
        if (to_date.hour() === 23) {
            // Add one hour
            to_date.add(1, 'hour');
        }

        if (from_date.hour() === 23) {
            // Add one hour
            from_date.add(1, 'hour');
        }

        filters.value.created_at = {
            from: from_date.format('YYYY-MM-DD'),
            to: to_date.format('YYYY-MM-DD')
        };
    }
    if(v.from && v.to) {
        isCustom.value = false;
    }
}, { deep: true })

const visible = ref(false);
const options = computed(() => [
    {
        label: 'common.lifetime',
        key: 'lifetime',
        value: {
            from: null,
            to: null
        }
    },
    {
        label: 'common.today',
        key: 'today',
        value: {
            from: today.clone().format('YYYY-MM-DD'),
            to: today.clone().format('YYYY-MM-DD')
        }
    },
    {
        label: 'common.yesterday',
        key: 'yesterday',
        value: {
            from: today.clone().subtract(1, 'days').format('YYYY-MM-DD'),
            to: today.clone().subtract(1, 'days').format('YYYY-MM-DD')
        }
    },
    {
        label: 'common.last-seven-days',
        key: 'last-seven-days',
        value: {
            from: today.clone().subtract(7, 'days').format('YYYY-MM-DD'),
            to: today.clone().format('YYYY-MM-DD')
        }
    },
    {
        label: 'common.this-month',
        key: 'this-month',
        value: {
            from: today.clone().startOf('month').format('YYYY-MM-DD'),
            to: today.clone().endOf('month').format('YYYY-MM-DD')
        }
    },
]);
const selected = ref(options.value[0]);

const onCustomDateClick = option => {
    selected.value = option
    dates.value = option.value;
    setTimeout(() => {
        isCustom.value = true;
    })
}

const onFilter = () => {
    filter();
    visible.value = false;
}

</script>

<style></style>