<template>
    <div>
        <div
            class="tw-mb-2 tw-gap-4 tw-flex-wrap tw-grid tw-grid-cols-12 tw-bg-white tw-p-4 tw-rounded tw-border tw-border-solid tw-border-gray-200">

            <div class="tw-col-span-12 md:tw-col-span-4 lg:tw-col-span-3 2xl:tw-col-span-2">
                <CreatedFilter />
            </div>
            <div class="tw-col-span-12 md:tw-col-span-4 lg:tw-col-span-3 2xl:tw-col-span-2">
                <DroppedFilter />
            </div>
            <div class="tw-col-span-12 md:tw-col-span-4 lg:tw-col-span-3 2xl:tw-col-span-2">
                <TreatedFilter />
            </div>
            <div class="tw-col-span-12 md:tw-col-span-4 lg:tw-col-span-3 2xl:tw-col-span-2">
                <ConfirmationFilter />
            </div>
            <div class="tw-col-span-12 md:tw-col-span-4 lg:tw-col-span-3 2xl:tw-col-span-2">
                <DeliveryFilter />
            </div>
            <div class="tw-col-span-12 md:tw-col-span-4 lg:tw-col-span-3 2xl:tw-col-span-2">
                <AgentFilter />
            </div>
            <div class="tw-col-span-12 md:tw-col-span-4 lg:tw-col-span-3 2xl:tw-col-span-2">
                <ProductFilter />
            </div>
            <div class="tw-col-span-12 md:tw-col-span-4 lg:tw-col-span-3 2xl:tw-col-span-2">
                <AdsFilter />
            </div>

            <div class="tw-col-span-12">
                <div
                    class="tw-flex tw-items-center tw-justify-end tw-gap-2">
                    <a-button v-if="false" intent="text"
                        class="!tw-w-[80px] !tw-h-[30px] !tw-min-h-0 tw-text-xs">{{
                            $t('buttons.cancel') }}</a-button>
                    <a-button @click="onFilter" class="!tw-w-[80px] !tw-h-[30px] !tw-min-h-0 tw-text-xs">{{
                        $t('buttons.filter')
                        }}</a-button>
                </div>
            </div>


            <div v-if="false"
                class="tw-p-1.5 ltr:tw-pr-3 rtl:tw-pl-3 tw-bg-white tw-rounded tw-border tw-border-solid tw-border-b-2 tw-border-gray-300 tw-shadow-smx tw-h-[32px] tw-flex tw-items-center">
                <div class="tw-flex tw-items-center tw-gap-2">
                    <icon icon="solar:box-linear" class="tw-text-lg tw-text-gray-400" />
                    <p class="tw-text-xs tw-text-gray-700">{{ $t('fields.products') }}</p>
                </div>
            </div>



        </div>
    </div>
</template>

<script setup>
import { provide, defineEmits } from 'vue';
import AdsFilter from './filters/AdsFilter.vue';
import CreatedFilter from './filters/CreatedFilter.vue';
import DroppedFilter from './filters/DroppedFilter.vue';
import TreatedFilter from './filters/TreatedFilter.vue';
import ConfirmationFilter from './filters/ConfirmationFilter.vue';
import DeliveryFilter from './filters/DeliveryFilter.vue';
import AgentFilter from './filters/AgentFilter.vue';
import ProductFilter from './filters/ProductFilter.vue';

const emit = defineEmits(['filter'])

provide('filter', () => emit('filter'))

const onFilter = () => {
    emit('filter')
}

</script>

<style></style>