<template>
    <div>
        <div class="tw-bg-white tw-p-2 tw-border tw-border-solid tw-rounded tw-border-gray-200 tw-min-h-[200px]x tw-pb-4">
            <div class="tw-flex tw-items-center tw-gap-2 tw-justify-between">
                <p class="tw-p-2 tw-font-bold tw-text-lg">{{ $t('common.kpis') }}</p>
                <div class="tw-relative" ref="exchange">
                    <div @click="exchangeVisible = !exchangeVisible" class="tw-relative tw-cursor-pointer hover:tw-bg-gray-50 tw-flex tw-items-center tw-gap-2 tw-text-end tw-p-1 tw-border tw-border-solid tw-border-gray-200 tw-rounded">
                        <p class="tw-text-xs tw-font-medium tw-font-[roboto]"><span class="tw-font-bold tw-text-black">1</span> USD</p>
                        <p class="tw-text-sm tw-font-medium tw-font-[roboto] tw-text-primary-500"><icon icon="uil:exchange-alt" /></p>
                        <p class="tw-text-xs tw-font-medium tw-font-[roboto]"><span class="tw-font-bold tw-text-black">4.88</span> LYD</p>
    
                    </div>
                    <div v-if="exchangeVisible"   class="tw-absolute tw-overflow-hidden tw-top-full tw-translate-y-1 tw-left-0 tw-w-full tw-h-10 tw-z-10 tw-border-b-2 tw-border tw-border-solid tw-bg-white tw-rounded">
                        <FormField v-model="usdtolyd" type="number" hide-label class="tw-scale-105" />
                    </div>
                </div>
            </div>

            <div class="tw-grid tw-grid-cols-5 tw-gap-4">
                <div class="tw-border-b-2 tw-border-solid tw-border-black tw-p-2">
                    <p class="tw-text-sm tw-text-gray-600 tw-font-semibold">{{ $t('pages.dashboard.kpis.total-orders') }}</p>
                    <p v-if="!loading" class="tw-text-xl tw-font-bold">{{ formatNumber(data.total_orders, {})  }}</p>
                    <p v-if="loading" class="tw-text-xl tw-font-bold tw-text-transparent tw-bg-blackx tw-w-20 tw-rounded tw-flex tw-relative">
                        <span>{{ 0 }}</span>
                        <icon icon="eos-icons:three-dots-loading" class="tw-text-black tw-text-3xl tw-absolute tw-top-1/2 -tw-translate-y-1/2" />
                    </p>
                </div>

                <div class="tw-border-b-2 tw-border-solid tw-border-emerald-500 tw-p-2">
                    <p class="tw-text-sm tw-text-gray-600 tw-font-semibold">{{ $t('pages.dashboard.kpis.confirmed') }}</p>
                    <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
                        <template v-if="!loading">
                            <p class="tw-text-xl tw-font-bold">{{ formatNumber(data.total_confirmed_orders, {})  }}</p>
                            <p class="tw-text-sm tw-font-medium tw-font-[roboto]">{{ getPercentage(data.total_confirmed_orders)  }}%</p>
                        </template>
                        <p v-if="loading" class="tw-text-xl tw-font-bold tw-text-transparent tw-bg-blackx tw-w-20 tw-rounded tw-flex tw-relative">
                            <span>{{ 0 }}</span>
                            <icon icon="eos-icons:three-dots-loading" class="tw-text-emerald-500 tw-text-3xl tw-absolute tw-top-1/2 -tw-translate-y-1/2" />
                        </p>
                    </div>
                </div>

                <div class="tw-border-b-2 tw-border-solid tw-border-green-500 tw-p-2">
                    <p class="tw-text-sm tw-text-gray-600 tw-font-semibold">{{ $t('pages.dashboard.kpis.delivered') }}</p>
                    <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
                        <template v-if="!loading">
                            <p class="tw-text-xl tw-font-bold">{{ formatNumber(data.total_delivered_orders, {})  }}</p>
                            <p class="tw-text-sm tw-font-medium tw-font-[roboto]">{{ getPercentage(data.total_delivered_orders, data.total_confirmed_orders)  }}%</p>
                        </template>
                        <p v-if="loading" class="tw-text-xl tw-font-bold tw-text-transparent tw-bg-blackx tw-w-20 tw-rounded tw-flex tw-relative">
                            <span>{{ 0 }}</span>
                            <icon icon="eos-icons:three-dots-loading" class="tw-text-green-500 tw-text-3xl tw-absolute tw-top-1/2 -tw-translate-y-1/2" />
                        </p>
                    </div>
                </div>

                <div class="tw-border-b-2 tw-border-solid tw-border-cyan-500 tw-p-2">
                    <p class="tw-text-sm tw-text-gray-600 tw-font-semibold">{{ $t('pages.dashboard.kpis.settled') }}</p>
                    <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
                        <template v-if="!loading">
                            <p class="tw-text-xl tw-font-bold">{{ formatNumber(data.total_settled_orders, {})  }}</p>
                            <p class="tw-text-sm tw-font-medium tw-font-[roboto]">{{ getPercentage(data.total_settled_orders, data.total_confirmed_orders)   }}%</p>
                        </template>
                        <p v-if="loading" class="tw-text-xl tw-font-bold tw-text-transparent tw-bg-blackx tw-w-20 tw-rounded tw-flex tw-relative">
                            <span>{{ 0 }}</span>
                            <icon icon="eos-icons:three-dots-loading" class="tw-text-cyan-500 tw-text-3xl tw-absolute tw-top-1/2 -tw-translate-y-1/2" />
                        </p>
                    </div>
                </div>

                <div class="tw-border-b-2 tw-border-solid tw-border-sky-500 tw-p-2">
                    <p class="tw-text-sm tw-text-gray-600 tw-font-semibold">{{ $t('pages.dashboard.kpis.ads') }}</p>
                    <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
                        <template v-if="!loading">
                            <p class="tw-text-xl tw-font-bold">{{ formatNumber(data.total_ad_spend)  }}</p>
                        </template>
                        <p v-if="loading" class="tw-text-xl tw-font-bold tw-text-transparent tw-bg-blackx tw-w-20 tw-rounded tw-flex tw-relative">
                            <span>{{ 0 }}</span>
                            <icon icon="eos-icons:three-dots-loading" class="tw-text-sky-500 tw-text-3xl tw-absolute tw-top-1/2 -tw-translate-y-1/2" />
                        </p>
                    </div>
                </div>

                <div class="tw-border-b-2 tw-border-solid tw-border-gray-500 tw-p-2">
                    <p class="tw-text-sm tw-text-gray-600 tw-font-semibold">{{ $t('pages.dashboard.kpis.total-turnover') }}</p>
                    <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
                        <template v-if="!loading">
                            <p class="tw-text-xl tw-font-bold">{{ formatNumber(total_turnover)  }}</p>
                            <div class="tw-fllex tw-justify-end tw-gap-1 tw-text-end">
                                
                            </div>
                        </template>
                        <p v-if="loading" class="tw-text-xl tw-font-bold tw-text-transparent tw-bg-blackx tw-w-20 tw-rounded tw-flex tw-relative">
                            <span>{{ 0 }}</span>
                            <icon icon="eos-icons:three-dots-loading" class="tw-text-gray-500 tw-text-3xl tw-absolute tw-top-1/2 -tw-translate-y-1/2" />
                        </p>
                    </div>
                </div>


                <div class="tw-border-b-2 tw-border-solid tw-border-gray-500 tw-p-2">
                    <p class="tw-text-sm tw-text-gray-600 tw-font-semibold">{{ $t('pages.dashboard.kpis.turnover-delivered') }}</p>
                    <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
                        <template v-if="!loading">
                            <p class="tw-text-xl tw-font-bold">{{ formatNumber(getUsdValue(data.total_turnover_delivered))  }}</p>
                            <div class="tw-fllex tw-justify-end tw-gap-1 tw-text-end">
                                
                            </div>
                        </template>
                        <p v-if="loading" class="tw-text-xl tw-font-bold tw-text-transparent tw-bg-blackx tw-w-20 tw-rounded tw-flex tw-relative">
                            <span>{{ 0 }}</span>
                            <icon icon="eos-icons:three-dots-loading" class="tw-text-gray-500 tw-text-3xl tw-absolute tw-top-1/2 -tw-translate-y-1/2" />
                        </p>
                    </div>
                </div>


                <div class="tw-border-b-2 tw-border-solid tw-border-gray-500 tw-p-2">
                    <p class="tw-text-sm tw-text-gray-600 tw-font-semibold">{{ $t('pages.dashboard.kpis.turnover-settled') }}</p>
                    <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
                        <template v-if="!loading">
                            <p class="tw-text-xl tw-font-bold">{{ formatNumber(getUsdValue(data.total_turnover_settled))  }}</p>
                            <div class="tw-fllex tw-justify-end tw-gap-1 tw-text-end">
                                
                            </div>
                        </template>
                        <p v-if="loading" class="tw-text-xl tw-font-bold tw-text-transparent tw-bg-blackx tw-w-20 tw-rounded tw-flex tw-relative">
                            <span>{{ 0 }}</span>
                            <icon icon="eos-icons:three-dots-loading" class="tw-text-gray-500 tw-text-3xl tw-absolute tw-top-1/2 -tw-translate-y-1/2" />
                        </p>
                    </div>
                </div>


                <div class="tw-border-b-2 tw-border-solid tw-border-cyan-500 tw-p-2">
                    <p class="tw-text-sm tw-text-gray-600 tw-font-semibold">{{ $t('pages.dashboard.kpis.profit') }}</p>
                    <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
                        <template v-if="!loading">
                            <p class="tw-text-xl tw-font-bold">{{ formatNumber(getUsdValue(data.total_turnover - data.total_shipping_cost) - data.total_product_cost - data.total_ad_spend)  }}</p>
                            <div class="tw-fllex tw-justify-end tw-gap-1 tw-text-end">
                                
                            </div>
                        </template>
                        <p v-if="loading" class="tw-text-xl tw-font-bold tw-text-transparent tw-bg-blackx tw-w-20 tw-rounded tw-flex tw-relative">
                            <span>{{ 0 }}</span>
                            <icon icon="eos-icons:three-dots-loading" class="tw-text-cyan-500 tw-text-3xl tw-absolute tw-top-1/2 -tw-translate-y-1/2" />
                        </p>
                    </div>
                </div>


                <div class="tw-border-b-2 tw-border-solid tw-border-yellow-500 tw-p-2">
                    <p class="tw-text-sm tw-text-gray-600 tw-font-semibold">{{ $t('pages.dashboard.kpis.cost-per-lead') }}</p>
                    <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
                        <template v-if="!loading">
                            <p class="tw-text-xl tw-font-bold">{{ formatNumber(data.total_confirmed_orders ? (data.total_ad_spend / data.total_confirmed_orders) : 0)  }}</p>
                        </template>
                        <p v-if="loading" class="tw-text-xl tw-font-bold tw-text-transparent tw-bg-blackx tw-w-20 tw-rounded tw-flex tw-relative">
                            <span>{{ 0 }}</span>
                            <icon icon="eos-icons:three-dots-loading" class="tw-text-yellow-500 tw-text-3xl tw-absolute tw-top-1/2 -tw-translate-y-1/2" />
                        </p>
                    </div>
                </div>

                <div class="tw-border-b-2 tw-border-solid tw-border-violet-500 tw-p-2">
                    <p class="tw-text-sm tw-text-gray-600 tw-font-semibold">{{ $t('pages.dashboard.kpis.cost-per-delivered') }}</p>
                    <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
                        <template v-if="!loading">
                            <p class="tw-text-xl tw-font-bold">{{ formatNumber(data.total_delivered_orders ? (data.total_ad_spend / data.total_delivered_orders) : 0)  }}</p>
                        </template>
                        <p v-if="loading" class="tw-text-xl tw-font-bold tw-text-transparent tw-bg-blackx tw-w-20 tw-rounded tw-flex tw-relative">
                            <span>{{ 0 }}</span>
                            <icon icon="eos-icons:three-dots-loading" class="tw-text-violet-500 tw-text-3xl tw-absolute tw-top-1/2 -tw-translate-y-1/2" />
                        </p>
                    </div>
                </div>

                <div class="tw-border-b-2 tw-border-solid tw-border-pink-500 tw-p-2">
                    <p class="tw-text-sm tw-text-gray-600 tw-font-semibold">{{ $t('pages.dashboard.kpis.average-order-value') }}</p>
                    <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
                        <template v-if="!loading">
                            <p class="tw-text-xl tw-font-bold">{{ formatNumber(getUsdValue(data.total_turnover / (data.total_delivered_orders + data.total_settled_orders)))   }}</p>
                        </template>
                        <p v-if="loading" class="tw-text-xl tw-font-bold tw-text-transparent tw-bg-blackx tw-w-20 tw-rounded tw-flex tw-relative">
                            <span>{{ 0 }}</span>
                            <icon icon="eos-icons:three-dots-loading" class="tw-text-pink-500 tw-text-3xl tw-absolute tw-top-1/2 -tw-translate-y-1/2" />
                        </p>
                    </div>
                </div>

                <div class="tw-border-b-2 tw-border-solid tw-border-indigo-500 tw-p-2">
                    <p class="tw-text-sm tw-text-gray-600 tw-font-semibold">{{ $t('pages.dashboard.kpis.profit-per-order') }}</p>
                    <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
                        <template v-if="!loading">
                            <p class="tw-text-xl tw-font-bold">{{ formatNumber((data.total_delivered_orders + data.total_settled_orders) ? (total_profit / (data.total_delivered_orders + data.total_settled_orders)) : 0)  }}</p>
                        </template>
                        <p v-if="loading" class="tw-text-xl tw-font-bold tw-text-transparent tw-bg-blackx tw-w-20 tw-rounded tw-flex tw-relative">
                            <span>{{ 0 }}</span>
                            <icon icon="eos-icons:three-dots-loading" class="tw-text-indigo-500 tw-text-3xl tw-absolute tw-top-1/2 -tw-translate-y-1/2" />
                        </p>
                    </div>
                </div>

                <div class="tw-border-b-2 tw-border-solid tw-border-orange-500 tw-p-2">
                    <p class="tw-text-sm tw-text-gray-600 tw-font-semibold">{{ $t('pages.dashboard.kpis.profit-per-unit') }}</p>
                    <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
                        <template v-if="!loading">
                            <p class="tw-text-xl tw-font-bold">{{ formatNumber(data.total_quantity ? (total_profit / data.total_quantity) : 0)  }}</p>
                        </template>
                        <p v-if="loading" class="tw-text-xl tw-font-bold tw-text-transparent tw-bg-blackx tw-w-20 tw-rounded tw-flex tw-relative">
                            <span>{{ 0 }}</span>
                            <icon icon="eos-icons:three-dots-loading" class="tw-text-orange-500 tw-text-3xl tw-absolute tw-top-1/2 -tw-translate-y-1/2" />
                        </p>
                    </div>
                </div>

                <div class="tw-border-b-2 tw-border-solid tw-border-red-500 tw-p-2">
                    <p class="tw-text-sm tw-text-gray-600 tw-font-semibold">{{ $t('pages.dashboard.kpis.shipping-cost') }}</p>
                    <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
                        <template v-if="!loading">
                            <p class="tw-text-xl tw-font-bold">{{ formatNumber(getUsdValue(data.total_shipping_cost))  }}</p>
                        </template>
                        <p v-if="loading" class="tw-text-xl tw-font-bold tw-text-transparent tw-bg-blackx tw-w-20 tw-rounded tw-flex tw-relative">
                            <span>{{ 0 }}</span>
                            <icon icon="eos-icons:three-dots-loading" class="tw-text-red-500 tw-text-3xl tw-absolute tw-top-1/2 -tw-translate-y-1/2" />
                        </p>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>

<script setup>
import { kpis } from '@/api/analytics';
import { ref, inject, computed } from 'vue';
import FormField from '@/components/form/FormField';
import { onClickOutside } from '@vueuse/core';


const exchange = ref(null);
onClickOutside(exchange, () => exchangeVisible.value = false);


const exchangeVisible = ref(false);
const filters = inject('filters')
const register = inject('register')
const data = ref({});
const usdtolyd = ref(4.88);
const loading = ref(false);


const getData = async () => {
    loading.value = true;
    await kpis(filters.value)
    .then(
        res => {
            console.log(res.data);
            data.value = res.data.data
            
        },
        err => {
            console.log(err)
        }
    )
    loading.value = false
}

const getUsdValue = (value) => {
    if(!value) return 0;

    return (value / usdtolyd.value).toFixed(2);
}

const formatNumber = (number, type = {style: 'currency', currency: 'USD'}) => {
    return new Intl.NumberFormat('en', { maximumFractionDigits: 2, ...type }).format(number);
}

const getPercentage = (number, base = data.value.total_orders) => {
    if(!base || !number) return 0;
    return ((number * 100) / base ).toFixed(2);
}

const total_turnover = computed(() => {
    if(loading.value) return 0;

    return getUsdValue(data.value.total_turnover)
})

const total_profit = computed(() => {
    if(loading.value) return 0;

    return total_turnover.value - data.value.total_product_cost - getUsdValue(data.value.total_shipping_cost) - data.value.total_ad_spend;
})

getData();
register(() => getData())
</script>

<style></style>