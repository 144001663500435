<template>
    <div class="tw-flex tw-items-center tw-gap-2">
        <city-edit-action :city="props.city" />
    </div>
</template>

<script setup>
import CityEditAction from './actions/CityEditAction';
import { defineProps } from 'vue';

const props = defineProps(['city'])
</script>

<style>

</style>